<template>
    <div class="local-shop-home">
        <transition appear name="fade" mode="out-in">
            <router-view/>
        </transition>
        <itc-floating-chat :sender="$store.getters.getUserName" :senderId="$store.getters.getConfirmationId"></itc-floating-chat>
    </div>
</template>
<script>
import FloatingChat from '@/components/shared/chat/FloatingChat'

export default {
    components: {
        'itc-floating-chat': FloatingChat,
    },
}
</script>